import React, { useEffect, useRef, useState } from 'react'
import DropDownList from './DropDownList';
import DropDownField from './DropDownField';
import classes from './DropDown.module.scss';

interface DropDownProps {
  id?: string;
  label?: string;
  placeholder?: string;
  list: Array<any>;
  listLabel?: string;
  secondaryList?: Array<any>;
  secondaryListLabel?: string;
  selectedItem?: any;
  onSearch?: (typed: string, receiveSuggestions?: any) => void;
  searchFor?: string;
  required?: boolean;
  error?: string;
  openByDefault?: boolean;
  setOpenRequestDialog?: Function;
  requestDialogLabel?: string;
  className?: any;
  selectable?: boolean;
  disabled?: boolean;
  withOutDropdown?: boolean;
  isTopView?: boolean;
  displayErrorMessage?: boolean;
  multiSelect?: boolean;
  dropdownPosition?: any;
  onScroll?: any
}

const DropDown: React.FC<DropDownProps> = ({
  id,
  label,
  placeholder,
  list,
  listLabel,
  secondaryList,
  secondaryListLabel,
  selectedItem,
  onSearch,
  searchFor,
  required,
  openByDefault,
  error,
  setOpenRequestDialog,
  requestDialogLabel,
  className,
  selectable,
  disabled,
  withOutDropdown = false,
  isTopView,
  displayErrorMessage,
  multiSelect = false,
  dropdownPosition,
  onScroll
}) => {
  const [dropListOpen, setDropListOpen] = useState(openByDefault);
  const [dropDownWidth, setDropDownWidth] = useState();

  useEffect(() => {
    setDropListOpen(openByDefault);
  }, [openByDefault]);

  /* The below line gets the width of the field */
  const componentRef = useRef();
  let computedStyle: any;
  if (componentRef?.current) {
    computedStyle = window.getComputedStyle(componentRef.current);
  }

  useEffect(() => {
    /* Sets dynamic width for the dropdown */
    if (computedStyle) setDropDownWidth(computedStyle.width);
  }, [componentRef, computedStyle?.width]);


  return (
    <div className={classes['rb-dropdown-root']} style={{
      position: dropdownPosition
    }}>
      <DropDownField
        withOutDropdown={withOutDropdown}
        id={id}
        label={label}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        componentRef={componentRef}
        selectedItem={selectedItem}
        dropListOpen={dropListOpen}
        setDropListOpen={setDropListOpen}
        error={error}
        className={className}
        selectable={selectable}
        displayErrorMessage={displayErrorMessage}
        multiSelect={multiSelect}
      />
      {!withOutDropdown && <DropDownList
        isTopView={isTopView}
        dropListOpen={dropDownWidth && dropListOpen}
        setDropListOpen={setDropListOpen}
        searchPlaceHolder='Search...'
        onSearch={onSearch}
        searchFor={searchFor}
        style={{ width: dropDownWidth }}
        primaryListLabel={listLabel}
        primaryListItems={list}
        secondaryListLabel={secondaryListLabel}
        secondaryListItems={secondaryList}
        receiveSuggestionsLabel={requestDialogLabel || label}
        setOpenRequestDialog={setOpenRequestDialog}
        multiSelect={multiSelect}
        onScroll={onScroll}
      />}
    </div>
  )
}

export default DropDown;