import React, { useEffect, useState } from 'react';
import { Controller } from "react-hook-form";
import Box from '../../../../../remitbee/components/box/Box';
import DropDown from '../../../../../remitbee/components/dropDown/DropDown';
import DropDownItem from '../../../../../remitbee/components/dropDown/DropDownItem';
import Typography from '../../../../../remitbee/components/typography/Typography';
import { getBeneficiaryFieldValues } from '../../../../../redux/recipient/payment-methods/actions';
import { snackbar } from '../../../../../remitbee/components/snackbar/SnackBar';
import Flag from '../../../../../remitbee/components/flag/Flag';
import classes from './FormFields.module.scss';
import theme from '../../../../../remitbee/theme/Theme';
interface FormFieldsSelectProps {
    field: any;
    value: any;
    errors: any[];
    onChange: any;
    control: any;
    getValues: any;
    updateAdditionalFields?: any;
    recipient?: {
        ben_country: string;
        ben_currency: string;
        ben_type: string;
        ben_payment_type: string;
    };
    setOpenRequestDialog?: Function;
    searchLimit?: number | string;
    isTopView?: boolean;
    searchFor?: string;
    dropdownTextColor?: any;
}

const FormFieldsSelect: React.FC<FormFieldsSelectProps> = ({
    field = {},
    value,
    errors,
    onChange,
    control = {},
    getValues,
    recipient,
    updateAdditionalFields,
    setOpenRequestDialog,
    searchLimit,
    searchFor,
    isTopView,
    dropdownTextColor,
    ...other
}) => {
    const [currentValues, setCurrentValue] = useState(getValues && getValues());
    const searchBarLimit = searchLimit || 7;
    const [selectedChildren, setSelectedChildren] = useState(null);
    const [selectedChildrenLabel, setSelectedChildrenLabel] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [subSelectedChildren, subSetSelectedChildren] = useState(null);
    const [subSelectedChildrenLabel, subSetSelectedChildrenLabel] = useState(null);
    const {
        placeholder,
        field_label,
        field_label_overwrite,
        is_required,
        field_value,
        id,
        additional_fields
    } = field;

    useEffect(() => {
        if (additional_fields?.values?.length) {
            const item = additional_fields.values.find((v: any) => v?.value?.toString() === value?.toString());
            if (item) {
                setSelectedItem(item);
                if (item.children) setSelectedChildren(item.children);
                if (item.hasChildren) fetchChildren(item.value);
            }
        }
        selectedLabel();
    }, [value, additional_fields]);

    // List items in case it's a select
    const renderListItem = (item: any, index: number, onChange: any, isChild: boolean, isSubChild: boolean) => {
        return (
            <DropDownItem
                key={index}
                value={item.value}
                data={item}
                onClick={(e) => {
                    // onClick from parent element
                    if (item?.onClick) item.onClick();
                    handleListChange(e, onChange, isChild, isSubChild)
                }}
            >
                {item.iso2 && <Box className={classes['rb-formfield-flag']}><Flag code={item.iso2} /></Box>}
                <Typography id={(typeof item.label) === 'object' ? item?.value : item?.label} color={dropdownTextColor ? dropdownTextColor : theme.palette.input.body}>{item.label}</Typography>
            </DropDownItem>
        )
    }

    // Custom click event
    const handleListChange = (e: { currentTarget: { getAttribute: (arg0: string) => string; }; }, onChange: (arg0: any) => void, isChild: boolean, isSubChild: boolean) => {
        const itemData = JSON.parse(e.currentTarget.getAttribute('data-item'));
        if (!itemData) return;
        if (onChange) onChange(itemData.value);

        if (isSubChild) subSetSelectedChildrenLabel(itemData.label);
        if (isChild) setSelectedChildrenLabel(itemData.label);
        setCurrentValue(getValues && getValues());
        if (isSubChild || isChild) {
            if (itemData?.children) setSelectedChildren(itemData?.children);
            if (itemData?.hasChildren) fetchChildren(itemData?.value, true);
        }
        if (field.handleChange) field.handleChange(itemData.value);
    }

    const fetchChildren = async (value: any, hasChildren?: boolean) => {
        const { data, errorMessage, success } = await getBeneficiaryFieldValues({
            country_id: recipient?.ben_country,
            currency_id: recipient?.ben_currency,
            ben_type: recipient?.ben_type,
            field_id: hasChildren ? selectedChildren?.field_id : id,
            field_value: value,
            method: recipient?.ben_payment_type,
        });
        if (success) {
            const { beneficiaryFieldValues } = data;
            updateAdditionalFields({ parent_id: hasChildren ? selectedChildren?.field_id : id, child: beneficiaryFieldValues }) // Japan, Ethopia etc have additional children from API
            if (hasChildren) {
                if (subSelectedChildren !== beneficiaryFieldValues) subSetSelectedChildren(beneficiaryFieldValues);
                return;
            }
            setSelectedChildren(beneficiaryFieldValues);
        } else snackbar.error(errorMessage, 3000);
    }

    const selectedLabel = () => {
        // To show field label instead of field value in dropdown
        selectedChildren && currentValues && selectedChildren?.values?.map((child: any) => {
            if (child.value === currentValues[selectedChildren.key]) {
                setSelectedChildrenLabel(child.label);
            }
        })
    }

    return (
        <>
            <DropDown
                id={field?.id ? field.id : ''}
                isTopView={isTopView}
                disabled={field.disabled}
                placeholder={placeholder}
                label={additional_fields?.label || field_label_overwrite || field_label}
                required={is_required === "1"}
                selectedItem={selectedItem && selectedItem.label}
                searchFor={additional_fields?.values?.length > searchBarLimit && (searchFor || 'label')}
                list={additional_fields?.values.map((item: any, index: number) => renderListItem(item, index, onChange, false, false))}
                error={errors[field_value] && errors[field_value].message?.toString()}
                setOpenRequestDialog={setOpenRequestDialog}
            />
            {selectedChildren && (
                <Box pt={8}>
                    <Controller
                        name={selectedChildren.key}
                        control={control}
                        rules={{
                            // Error message in case it's a required field
                            required: 'This field is required.',
                        }}
                        render={({ field: { onChange, value } }) => (
                            <DropDown
                                placeholder={selectedChildren.placeholder}
                                label={selectedChildren.label}
                                required={true}
                                selectedItem={selectedChildrenLabel}
                                searchFor={selectedChildren.values?.length > 7 && 'label'}
                                list={selectedChildren.values.map((item: any, index: number) => renderListItem(item, index, onChange, true, false))}
                                error={errors[selectedChildren.key] && errors[selectedChildren.key].message?.toString()}
                            />
                        )}
                    />
                </Box>
            )}
            {subSelectedChildren && (
                <Box pt={8}>
                    <Controller
                        name={subSelectedChildren?.key}
                        control={control}
                        rules={{
                            // Error message in case it's a required field
                            required: 'This field is required.',
                        }}
                        render={({ field: { onChange } }) => (
                            <DropDown
                                placeholder={subSelectedChildren?.placeholder}
                                label={subSelectedChildren?.label}
                                required={true}
                                selectedItem={subSelectedChildrenLabel}
                                searchFor={subSelectedChildren?.values?.length > 7 && 'label'}
                                list={subSelectedChildren?.values.map((item: any, index: number) => renderListItem(item, index, onChange, false, true))}
                                error={errors[subSelectedChildren?.key] && errors[subSelectedChildren?.key].message?.toString()}
                            />
                        )}
                    />
                </Box>)
            }
        </>
    );
};

export default FormFieldsSelect;
