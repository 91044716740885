import React, {  FC, useEffect, useState } from 'react';
import classes from './../Input.module.scss';
import Box from '../../box/Box';
import Input from '../Input';
import Dropdown from '../../dropDown/DropDown';
import Typography from '../../typography/Typography';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData' 
dayjs.extend(localeData);
import DropDownItem from '../../dropDown/DropDownItem';

const DateInput: FC<{
    label?: string;
    date: string;
    required?: boolean;
    error?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
}> = ({
    label,
    required,
    error,
    date,
    onChange,
    disabled
}) => {
    const [selectedDate, setSelectedDate] = useState({
        day: '',
        month: '',
        year: ''
    });
    const [validationError, setValidationError] = useState(false);

    const validateDate = ({ day, year }, dateAsString) => {
        if (parseInt(day) === 0 || parseInt(day) > 31) {
            return false;
        } else if (year.replaceAll(' ', '').length === 4 && parseInt(year) < 1900) {
            return false;
        } else if (year.replaceAll(' ', '').length === 4 &&
            day.replaceAll(' ', '').length > 1 &&
            !dayjs(dateAsString)?.isValid()) {
            return false;
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (date && date.split('-') && date.split('-').length) {
            setSelectedDate({
                day: date.split('-')[2],
                month: date.split('-')[1],
                year: date.split('-')[0]
            })
        }
    }, [date]);

    useEffect(() => {
        const dateAsString = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`;

        if (selectedDate.year === '' || selectedDate.month === '' || selectedDate.year === '') return
        if (validateDate(selectedDate, dateAsString)) {
            setValidationError(false);
        } else {
            setValidationError(true);
        }
        handleChange(dateAsString);
    }, [selectedDate]);

    const handleChange = (value: string) => {
        if (onChange) onChange(value);
    };

    const renderMonths = () => {
        return dayjs.months().map((month, index) => (
            <DropDownItem key={index} onClick={() => onChangeValue('month', index + 1)}>
                {month}
            </DropDownItem>
        ));
    }

    const onChangeValue = (name: string, value: string | number) => {
        setSelectedDate({ ...selectedDate, [name]: value });
    };

    return (
        <>
            {label && <Typography variant='body2' className={classes['rb-input-label']}>
                <Box display='flex'>
                    <Box flexGrow={1}>
                        {label}
                        {required && <span className={classes['rb-input-required']} />}
                    </Box>
                </Box>
            </Typography>}
            <Box display='flex' className={classes['rb-input-phonenumber-container']}>
                <Input
                    id='day'
                    fullWidth
                    disabled={disabled}
                    format='##'
                    label={!label ? 'Day' : null}
                    required={required}
                    placeholder={'01'}
                    onChange={(value: string) => onChangeValue('day', value)}
                    value={selectedDate.day}
                    className={error || validationError ? classes['rb-input-error'] : null}
                />
                <Box className={classes['rb-input-date-selector']}>
                    <Dropdown
                        id='month'
                        disabled={disabled}
                        list={renderMonths()}
                        label={!label ? 'Month' : null}
                        required={required}
                        placeholder={'Month'}
                        className={error || validationError ? classes['rb-input-error'] : null}
                        selectedItem={selectedDate.month && dayjs.months()[Number(selectedDate.month) - 1]}
                    />
                </Box>
                <Input
                    id='year'
                    disabled={disabled}
                    fullWidth
                    format='####'
                    label={!label ? 'Year' : null}
                    required={required}
                    placeholder={'2000'}
                    onChange={(value: string) => onChangeValue('year', value)}
                    value={selectedDate.year}
                    className={error || validationError ? classes['rb-input-error'] : null}
                />
            </Box>
            {validationError && <Typography variant='body3' className={classes['rb-input-errorText']}>{'Invalid date.'}</Typography>}
            {!validationError && error && <Typography variant='body3' className={classes['rb-input-errorText']}>{error}</Typography>}
        </>
    );
};

export default DateInput;
