import React, { useEffect, useState } from 'react'
import Typography from '../../typography/Typography';
import classes from './../Input.module.scss';
import Box from '../../box/Box';

const DecimalInput: React.FC<{
    id?: string,
    name?: string,
    type?: string,
    fullWidth?: boolean,
    label?: string,
    placeholder?: string,
    required?: boolean,
    helperText?: string,
    /**
     * Adds a Help icon with tooltip containing the text, `text - "hello"` | `element - <span>hello</span>`
     */
    hintText?: string | React.ReactNode,
    error?: string,
    regex?: any,
    disabled?: boolean,
    onChange?: (() => void) | any,
    browserAutoComplete?: string,
    style?: object,
    /**
     * Any one of the value - | `bottom-end` | `bottom-start` | `bottom` | `left-end` | `left-start` | `left` | `right-end` | `right-start` | `right` | `top-end` | `top-start` | `top`;
     */
    hintPosition?: string,
    /**
     * string 
     */
    value?: string,
    /* If format is passed, onChange returns the value instead of event */
    format?: string,
    autoFocus?: boolean,
    startAdornment?: any,
    className?: any,
    readOnly?: boolean,
    endAdornment?: any,
    componentRef?: any;
}> = ({
    id,
    name,
    type = 'text',
    fullWidth,
    label,
    placeholder,
    required,
    helperText,
    hintText,
    error,
    regex,
    disabled,
    readOnly,
    onChange,
    browserAutoComplete = 'off',
    style,
    hintPosition = 'top-end',
    value,
    format,
    autoFocus,
    className,
    startAdornment,
    endAdornment,
    componentRef,
}) => {
    const [newValue, setNewValue] = useState(value);
    const [errorOnBlur, setErrorOnBlur] = useState(error);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        setErrorOnBlur(error);
    }, [error]);

    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // If there is an error but user changes the value, clean it
        if (errorOnBlur) setErrorOnBlur(null);

        const inputValue = event.target.value;

        // If a format is provided, apply formatting to the input value
        let formattedValue = inputValue.replace(/[^0-9]/g, "");
        if(formattedValue === '000'){
            formattedValue = '00'
        }
        if (format) {
            // Remove non-digit characters from the input value
            const numericValue = inputValue.replace(/\D/g, '');

            // Apply formatting based on the provided format
            let formatted = '';
            let j = 0;
            for (let i = 0; i < format.length && j < numericValue.length; i++) {
                if (format[i] === '#') {
                    formatted += numericValue[j];
                    j++;
                } else {
                    formatted += format[i];
                }
            }

            formattedValue = formatted;
        }

        setNewValue(formattedValue);

        if (onChange) {
            !format ? onChange(event) : onChange(formattedValue);
        }

    };

    const handleFocus = () => {
        setFocused(true); 
      };

    const handleBlur = () => {
        if (value === '') {
            setFocused(false);
          }
        setErrorOnBlur(error);
    }

    return <>
        <div ref={componentRef && componentRef} className={fullWidth ? classes['rb-input-container-fullWidth'] : classes['rb-input-container']}>
            {label && <Typography variant='body2' className={classes['rb-input-label']}>
                <Box display='flex'>
                    <Box flexGrow={1}>
                        {label}
                        {required && <span className={classes['rb-input-required']} />}
                    </Box>
                </Box>
            </Typography>}
            { <div className={classes['rb-adornment-decimal-input-container']}>
                <div className={classes['input-prefix']}>{'$0.'}</div>
                <input
                    id={id}
                    value={newValue}
                    name={name}
                    placeholder={focused ? '00' :placeholder}
                    disabled={disabled}
                    readOnly={readOnly}
                    type={type}
                    autoComplete={browserAutoComplete}
                    onBlur={handleBlur}
                    className={`
                        ${classes['rb-input-root-decimal']}
                        ${fullWidth ? classes['rb-input-container-fullWidth'] : null}
                        ${errorOnBlur ? classes['rb-input-error'] : null}
                        ${startAdornment ? classes['rb-adornment-input-field'] : null}
                        ${className}
                    `}
                    onChange={handleChange}
                    style={style}
                    autoFocus={autoFocus}
                    onFocus={handleFocus}
                    maxLength={newValue?.charAt(2) === '0' ? 2 :newValue && newValue?.charAt(0) !== '0' && 2}
                />
            </div>
            }
            {helperText && !errorOnBlur && <Typography variant='body3' id={`${id}-helper-text`} className={classes['rb-input-hint']}>{helperText}</Typography>}
            {errorOnBlur && <Typography variant='body3' id={`${id}-error-text`} className={classes['rb-input-errorText']}>{errorOnBlur}</Typography>}
        </div>
    </>
}

export default DecimalInput;