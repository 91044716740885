import React, { useEffect, useRef, useState } from 'react'
import DropDownList from '../../dropDown/DropDownList';
import Input from '../Input';
import Script from 'next/script';
import DropDownItem from '../../dropDown/DropDownItem';
import Typography from '../../typography/Typography';

declare global {
    interface Window {
        google: any;
    }
}

const AddressInput: React.FC<{
    id?: string;
    address?: string;
    onAddressChange?: (event: any) => void;
    label?: string;
    placeholder?: string;
    list: Array<any>;
    listLabel?: string;
    secondaryList?: Array<any>;
    secondaryListLabel?: string;
    required?: boolean;
    error?: string;
    openByDefault?: boolean;
    setOpenRequestDialog?: Function;
    requestDialogLabel?: string;
    className?: any;
    selectable?: boolean;
    disabled?: boolean;
    withOutDropdown?: boolean;
    isTopView?: boolean;
    displayErrorMessage?: boolean;
    multiSelect?: boolean;
    dropdownPosition?: any;
    onScroll?: any;
    onChange?: (event: any) => void;
}
> = ({
    id,
    label,
    address,
    placeholder,
    onAddressChange,
    onChange,
    // list,
    listLabel,
    secondaryList,
    secondaryListLabel,
    required,
    // openByDefault,
    // error,
    setOpenRequestDialog,
    requestDialogLabel,
    // className,
    // selectable,
    disabled,
    // withOutDropdown = false,
    isTopView,
    // displayErrorMessage,
    multiSelect = false,
    // dropdownPosition,
    onScroll
}) => {
    const [dropListOpen, setDropListOpen] = useState(false);
    const [dropDownWidth, setDropDownWidth] = useState();
    // const [inputValue, setInputValue] = useState('');
    const [predictions, setPredictions] = useState([]);
    const [listData, setListData] = useState([]);

    // useEffect(() => {
    //     setDropListOpen(openByDefault);
    // }, [openByDefault]);

    const componentRef = useRef()
    let computedStyle
    if (componentRef?.current) {
        computedStyle = window.getComputedStyle(componentRef.current);
    }

    useEffect(() => {
        /* Sets dynamic width for the dropdown */
        if (computedStyle) setDropDownWidth(computedStyle.width);
    }, [componentRef, computedStyle?.width, listData, predictions]);


    const handleListChange = (e: { currentTarget: { getAttribute: (arg0: string) => string; }; }, onChange: (arg0: any) => void, isChild: boolean) => {
        const itemData = JSON.parse(e.currentTarget.getAttribute('data-item'));         
        if (!itemData) return;
        if (onChange) onChange(itemData.structured_formatting.main_text);
        getPlaceDetails(itemData.place_id);
    }

    const getPlaceDetails = (placeId) => {
        let promise = new Promise(resolve => {
            const params = {
                placeId,
                fields: ['address_component']
            }

            const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

            placesService.getDetails(params, (place) => {
                if (!place) return
                onAddressChange(place)
            });
        });

        return promise;
    }

    // List items in case it's a select
    const renderListItem = (item: any, index: number, onChange, isChild: boolean) => {
        return (
            <DropDownItem
                key={index}
                value={item.value}
                data={item}
                onClick={(e) => {
                    // onClick from parent element
                    if (item?.onClick) item.onClick();
                    handleListChange(e, onChange, isChild)
                }}
            >
                <Typography id={item?.description}>{item.description}</Typography>
            </DropDownItem>
        )
    }


    const handleAddressChange = (event: any) => {
        onAddressChange(event);
        onChange(event.target.value)
        setDropListOpen(true)
        loadSuggestions(event.target.value);
        if(event.target.value === '') setDropListOpen(false)
    };

    const loadSuggestions = (input) => {
        if (window.google) {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            const request = {
                input: input,
                types: ['geocode'],
                componentRestrictions: { country: 'CA' }
            };
            autocompleteService.getPlacePredictions(request, (predictions) => {
                if (predictions ) {
                    setDropListOpen(true)
                    setListData(predictions.map((item, index) => (renderListItem(item, index, onChange, false))))
                    setPredictions(predictions)
                }else{
                    setDropListOpen(true)
                    setListData([])
                    setPredictions([])
                }
            });
        }

    };

    return (
        <div>
            <Script
                src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places&language=en`}
            />
            <Input
                componentRef={componentRef}
                label={label}
                fullWidth
                id={id}
                disabled={disabled}
                placeholder={placeholder}
                onChange={handleAddressChange}
                value={address}
                type='text'
                required={required}
            />
             <DropDownList
                isTopView={isTopView}
                dropListOpen={dropListOpen}
                setDropListOpen={setDropListOpen}
                style={{ width: dropDownWidth }}
                primaryListLabel={listLabel}
                primaryListItems={listData}
                secondaryListLabel={secondaryListLabel}
                secondaryListItems={secondaryList}
                receiveSuggestionsLabel={requestDialogLabel || label}
                setOpenRequestDialog={setOpenRequestDialog}
                multiSelect={multiSelect}
                onScroll={onScroll}
            />
        </div>
    )
}

export default AddressInput;