import React, { useEffect, useState } from 'react';
import { Controller, set } from "react-hook-form";
import Box from '../../../../../remitbee/components/box/Box';
import Divider from '../../../../../remitbee/components/divider/Divider';
import Input from '../../../../../remitbee/components/input/Input';
import Typography from '../../../../../remitbee/components/typography/Typography';
import PhoneNumberInput from '../../../../../remitbee/components/input/phoneNumberInput/PhoneNumberInput';
import DateInput from '../../../../../remitbee/components/input/dateInput/DateInput';
import theme from '../../../../../remitbee/theme/Theme';
import classes from './FormFields.module.scss';
import { useTranslation } from 'next-i18next';
import FormFieldsSelect from './FormFieldsSelect';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing'
import { useMediaQuery } from '@mui/material';
import AddressInput from '../../../../../remitbee/components/input/addressInput/AddressInput';
import DecimalInput from '../../../../../remitbee/components/input/decimalInput/DecimalInput';

interface FormFieldsProps {
  sectionTitle?: string;
  fields?: any[];
  benGroup?: string;
  benType?: string;
  benCountry?: number;
  control?: any;
  errors?: any;
  clearErrors?: any;
  trigger?: any;
  setValue?: any;
  getValues?: any;
  addRecipientForm?: object;
  countryCode?: string;
  callingCode?: number;
  setError?: Function;
  thirdPartyValidationHandler?: any;
  onCountryCodeChange?: any;
  defaultPhoneCode?: number;
  preventAutoFill?: boolean;
  setOpenRequestDialog?: Function;
  fullWidth?: boolean;
  setPasswordRegCheck?: any;
  setIsPasswordValue?: any;
  id?: string;
  phoneNumberCodeWidth?: number;
  dropdownTextColor?: any;
}

const FormFields: React.FC<FormFieldsProps> = ({
  sectionTitle,
  fields = [],
  benGroup,
  benType,
  benCountry,
  control,
  errors,
  setError,
  thirdPartyValidationHandler,
  onCountryCodeChange,
  trigger,
  setValue,
  getValues,
  //these props are initial values, they should be renamed
  addRecipientForm = {},
  countryCode,
  callingCode,
  defaultPhoneCode,
  clearErrors,
  preventAutoFill,
  setOpenRequestDialog,
  fullWidth,
  setPasswordRegCheck,
  setIsPasswordValue,
  id,
  phoneNumberCodeWidth,
  dropdownTextColor,
  ...other
}) => {
  // If user already started adding a ben, form fields will be restored from redux
  useEffect(() => {
    if (Object?.keys(addRecipientForm)?.length && !preventAutoFill) {
      // Object.keys(addRecipientForm).map(key => {
      //   setValue(key, addRecipientForm[key]);
      // })
      fields.map(f => {
        const field = f.field_value;
        setValue(field, addRecipientForm[field]);
      })
    }
  }, [addRecipientForm]);
  const [passwordRef, setPasswordRef] = useState("");
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const { t } = useTranslation();
  const renderFields = () => {
    const fieldsToRender = fields && benType ? fields.filter(e => e.group_no === benType || !e.group_no)
      : fields && fields.filter(e => e.group_no === benGroup || !e.group_no);

    const handleThirdPartyValidationV2 = (value: string, field_id: string, field_value: string, field_error_message: string) => {
      thirdPartyValidationHandler && thirdPartyValidationHandler(value, field_id)
        .then((value: any) => {
          const { is_valid, error_message } = value;
          if (!is_valid) {
            setError && setError(field_value, { message: is_valid ? null : error_message || field_error_message || t(`common:invalid.${field_value}`) })
          } else {
            if (clearErrors) clearErrors(field_value);
          }
        })
    }

    const handlePasswordValidation = (value: string) => {
      if (!value || value === '') return
      const hasUpperCase = /^(?=.*[A-Z]).*.$/.test(value);
      const hasLowerCase = /^(?=.*[a-z]).*.$/.test(value);
      const hasMinLength = value?.length >= 8;
      const hasMaxLength = value?.length <= 40;
      const hasNumber = /^(?=.*[0-9]).*.$/.test(value);
      const hasSpecialChar = /^(?=.*[~`!@#$%^&*()_\-+={[}\]|\:;"'<,>.?\/]).*.$/.test(value);

      if (setPasswordRegCheck) setPasswordRegCheck(
        {
          minChar: hasMinLength,
          oneUpper: hasUpperCase,
          oneLower: hasLowerCase,
          oneSpecial: hasSpecialChar,
          oneNumber: hasNumber
        }
      )

      return hasUpperCase && hasLowerCase && hasMinLength && hasMaxLength && hasNumber && hasSpecialChar;
    };

    const getFromAddress = (type, address, field = 'long_name') => {
      if (!address) {
        return '';
      }

      try {
        const detail = address.address_components.filter(component => component.types.includes(type)).map(c => c[field])[0];
        return (detail || '').toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      } catch (error) {
        return '';
      }
    }

    const onAddressChange = (place: any) => {
      const province = getFromAddress('administrative_area_level_1', place, 'short_name');
      const province2 = getFromAddress('administrative_area_level_1', place, 'long_name');
      const city = getFromAddress('locality', place);
      const postalCode = getFromAddress('postal_code', place);
      const address = getFromAddress('route', place);
      const streetNumber = getFromAddress('street_number', place);
      const country = getFromAddress('country', place);

      if (fields.find(e => e.field_name === 'cus_address1')) {
        setValue('cus_address2', streetNumber);
        setValue('cus_city', city);
        setValue('cus_province', province);
        setValue('cus_postal', postalCode);
      }

      if (fields.find(e => e.field_name === 'address')) {
        setValue('unit', streetNumber);
        setValue('city', city);
        setValue('province', province2);
        setValue('postal', postalCode);
      }

    }
    return <>
      <Box display='flex' style={{ flexWrap: 'wrap',  gap: '0px 24px'  }} >
        {fieldsToRender.map((field, index) => (
          // This loads the column width based on HALF section or not
          <Box key={index} className={classes[(field.section_width === 'HALF' && !fullWidth) ? 'rb-formfield-half' : field.section_width === 'THREEQUARTERS' ? 'rb-formfield-threeQuarters' : 'rb-formfield-full']}>
            <Controller
              name={field.field_value}
              control={control}
              rules={{
                // Error message in case it's a required field
                required: field.is_required === "1" &&( field.type === 'decimal' ? t('common:invalid.empty_micro-deposit-error') : 'This field is required.'),
                pattern: {
                  value: field.regex && new RegExp(field.regex),
                  // Error message in case it's a wrong regex
                  message: field.error_message || 'Invalid format.',
                },
                validate: (value) => {
                  if(field.type === 'decimal'){
                    if(parseInt(value) < field.startAmount || parseInt(value) > field.endAmount){
                      return t('common:invalid.micro-deposit-error')
                    }
                    if(parseInt(value) === 0){
                      return t('common:invalid.empty_micro-deposit-error')
                    }
                    if(parseInt(value) < field.startAmount){
                      return t('common:invalid.micro-deposit-error')
                    }
                  }
                  if ((field.type === 'password' || field.id === 'password') && !field.skipValidation) {
                    return handlePasswordValidation(value) || 'Invalid password format.';
                  }
                  if (field.checkValue && field.checkValue !== value) {
                    return 'Enter same password as above.'
                  }
                  return true;
                }
              }}
              render={({ field: { onChange, value } }) => {
                // This will determine if the field is an input, select or phone number field
                if (field.field_name === 'ben_phone1') return (
                  <PhoneNumberInput
                    withOutDropdown={field.withOutDropdown}
                    id={field.id}
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                    label={field.field_label_overwrite || field.field_label + `${field.is_required === "0" ? ' (optional)' : ''}`}
                    required={field.is_required === "1"}
                    codeList={[{
                      countryCode: countryCode,
                      callingCode: callingCode || 1,
                      countryId: benCountry
                    }]}
                    phoneNumber={value}
                    defaultPhoneCode={defaultPhoneCode}
                    onCountryCodeChange={onCountryCodeChange}
                    onPhoneNumberChange={(e: any) => {
                      onChange(e);
                      if (trigger) trigger(field.field_value);
                    }}
                    codeWidth={phoneNumberCodeWidth}
                    error={errors[field.field_value] && (field.error ? field.error : errors[field.field_value].message?.toString())}
                  />
                )
                if (field.field_name === 'cus_address1' || field.field_name === 'address') return (
                  <AddressInput
                    id={field.id}
                    label={field.field_label}
                    address={value}
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                    onChange={onChange}
                    onAddressChange={onAddressChange}
                    list={field.list}
                    listLabel={field.listLabel}
                    secondaryList={field.secondaryList}
                    secondaryListLabel={field.secondaryListLabel}
                    required={field.is_required === "1"}
                    openByDefault={field.openByDefault}
                    error={errors[field.field_value] && errors[field.field_value].message?.toString()}
                    setOpenRequestDialog={setOpenRequestDialog}
                    className={classes['rb-address-input']}
                    isTopView={field.isTopView}
                    displayErrorMessage={field.displayErrorMessage}
                    multiSelect={field.multiSelect}
                    dropdownPosition={field.dropdownPosition}
                    onScroll={field.onScroll}
                  />
                )
                if (field.type === 'date') return (
                  <DateInput
                    label={field.field_label}
                    disabled={field.disabled}
                    required={field.is_required}
                    onChange={onChange}
                    date={value}
                    error={errors[field.field_value] && errors[field.field_value].message?.toString()}
                  />
                )
                if (field.additional_fields) return (
                  <>
                    <FormFieldsSelect
                      dropdownTextColor={dropdownTextColor}
                      isTopView={field.isTopView}
                      field={field}
                      errors={errors}
                      value={value}
                      onChange={onChange}
                      control={control}
                      getValues={getValues}
                      searchLimit={7}
                      setOpenRequestDialog={setOpenRequestDialog}
                      searchFor={field?.search_for}
                      {...other}
                    />
                  </>
                )
                if (field.type === 'decimal') return (
                  <DecimalInput
                    fullWidth
                    className={classes['rb-password-input']}
                    id={field?.field_name || field?.id}
                    disabled={field.disabled}
                    placeholder={field.placeholder || (field.field_name && field.field_name.includes('dob') ? field.field_description : '')}
                    label={field.field_label_overwrite || field.field_label}
                    required={field.is_required === "1"}
                    onChange={(e: any) => {
                        const value = e.target.value.replace(/\D/g, ''); 
                        if (e.target.value.length <= 2) {
                          if (parseInt(e.target.value) >= field.startAmount) {
                            if (clearErrors) clearErrors(field.field_value);
                            onChange(value.padStart(2, '0'));
                        }else {      
                          onChange(value.padStart(2, '0'));
                        }
                        } else {
                          if (parseInt(e.target.value) >= field.startAmount && parseInt(e.target.value) <= field.endAmount) {
                            if (clearErrors) clearErrors(field.field_value);
                            onChange(value.slice(1, 3));
                        }else {
                          onChange(value.slice(1, 3));
                        }
                      } 
                    }}
                    value={value}
                    error={errors[field.field_value] && errors[field.field_value].message?.toString()}
                    type={'text'}
                    helperText={field.helperText}
                  />
                );
                else return (
                  <>
                    <Input
                      fullWidth
                      className={classes['rb-password-input']}
                      id={field?.field_name || field?.id}
                      disabled={field.disabled}
                      placeholder={field.placeholder || (field.field_name && field.field_name.includes('dob') ? field.field_description : '')}
                      label={field.field_label_overwrite || field.field_label}
                      required={field.is_required === "1"}
                      onChange={(e: any) => {
                        if (field.passwordRegCheck) {
                          setPasswordRef(e.target.value);
                        }
                        if (field.third_party_validation_method) {
                          // handleThirdPartyValidation(e.target.value, field.field_value.toLowerCase(), field.field_name.toLowerCase(), field.third_party_validation_method, field.regex, field.error_message)

                          handleThirdPartyValidationV2(e.target.value, field.id, field.field_value.toLowerCase(), field.error_message)
                        }
                        onChange(e.target.value);
                        if (e.target.value.length !== 0 && setIsPasswordValue) setIsPasswordValue(true);
                        if (e.target.value.length === 0) {
                          if (setIsPasswordValue) setIsPasswordValue(false);
                          if (setPasswordRegCheck) setPasswordRegCheck(
                            {
                              minChar: false,
                              oneUpper: false,
                              oneLower: false,
                              oneSpecial: false,
                              oneNumber: false
                            }
                          )
                        }
                        if (trigger) trigger(field.field_value);
                      }}
                      value={value}
                      hintText={field.tooltip}
                      error={errors[field.field_value] && errors[field.field_value].message?.toString()}
                      type={field.type === 'password' ? 'password' : 'text'}
                      endAdornment={(field.field_label === 'Password' || field.field_label === 'New password' || field.endAdornment) && field.endAdornment}
                    />
                  </>
                )
              }}
            />
            {/* {(index < fieldsToRender.length - 1 && (index !== fieldsToRender.length - 2 && field.section_width === 'HALF')) && <Spacing variant='betweenSectionToInputFields'></Spacing>} */}
            {index < fieldsToRender.length - 1 &&
              <div style={index === fieldsToRender.length - 2 && (!fullWidth && field.section_width === 'HALF') && !isMobile ? { display: 'none' } : null}>
                <Spacing variant='betweenSectionToInputFields'></Spacing>
              </div>
            }
          </Box>
        )
        )}
      </Box>
    </>;
  }

  if (fields && fields.length) return (
    <>
      {sectionTitle && (
        <>
          <Typography
            variant={isMobile ? 'h4' : 'h5'}
            weight='semibold'
            id={id}
            color={theme.palette.primary.navy}
          >{sectionTitle}</Typography>
          <Spacing variant='titleToDivider'></Spacing>
          <Divider />
          <Spacing variant='betweenSectionToInputFields'></Spacing>
        </>
      )}
      {renderFields()}
    </>
  );

  return null
};

export default FormFields;