import React, { useEffect, useRef, useState } from 'react'
import classes from './DropDown.module.scss'
import SearchIcon from '../../icons/SearchIcon';
import DropDownItem from './DropDownItem';
import Typography from '../typography/Typography';
import Box from '../box/Box';
import theme from '../../theme/Theme';

type DropDownListProps = {
    /**
     * Same `state` which is used in `DropDownField` component
     */
    dropListOpen: boolean,
    /**
     * Search Functionality, `optional`
     */
    onSearch?: Function,
    /**
     * Deafult search of the input, the search will be done in determined prop as string
     */
    searchFor?: string,
    /**
     * List label for the primary list `optional`
     */
    primaryListLabel?: string | React.ReactNode;
    /**
     * List of `elements` expected with logo, `mandatory`
     * @example <div>
               <span style={{width:'42px',height:'42px'}}>
               <img style={{ width:'100%'}} src={logo} alt={'test'}/>
               </span>
               <span>Title</span>
             </div>
     */
    primaryListItems: any;
    /**
     * List label for the secondary list `optional`
     */
    secondaryListLabel?: string | React.ReactNode;
    /**
    * List of `elements` expected with logo, `optional`
    * @example <div>
              <span style={{width:'42px',height:'42px'}}>
              <img style={{ width:'100%'}} src={logo} alt={'test'}/>
              </span>
              <span>Title</span>
            </div>
    */
    secondaryListItems?: Array<string | React.ReactNode>;
    /**
     * If Search feature is added, can use this as placeholder : `optional`
     */
    searchPlaceHolder?: string;
    style?: any;
    /**
     * Handles the state change of dropDownList, Recommend to use `useState`
     */
    setDropListOpen?: any;
    onClick?: any;
    setOpenRequestDialog?: Function;
    receiveSuggestionsLabel?: string;
    isTopView?: boolean;
    multiSelect?: boolean;
    onScroll?: any;
    dropdownListClasses?: any;
    primaryListClasses?: any;
    innerRef?: any;
    handleOutsideClick?: any;
}

const DropDownList: React.FC<DropDownListProps> = ({
    dropListOpen = false,
    setDropListOpen,
    onSearch,
    searchPlaceHolder,
    primaryListLabel,
    primaryListItems,
    secondaryListLabel,
    secondaryListItems,
    searchFor,
    onClick,
    setOpenRequestDialog,
    receiveSuggestionsLabel,
    isTopView = false,
    multiSelect,
    onScroll,
    dropdownListClasses,
    primaryListClasses,
    innerRef,
    handleOutsideClick,
    ...rest
}) => {
    const [open, setOpen] = useState(dropListOpen);
    const componentRef = useRef(null);
    const [query, setSearchQuery] = useState(null);
    const [primaryList, setPrimaryList] = useState(primaryListItems);
    const [receiveSuggestions, setReceiveSuggestions] = useState<any>();

    useEffect(() => {
        if (setOpenRequestDialog && receiveSuggestionsLabel)
            setReceiveSuggestions(
                <DropDownItem id='not_found_country' key="notfound" onClick={()=>setOpenRequestDialog(true)}>
                    <Typography align='left' className={classes[`rb-list-not-found`]}>
                        <span>
                            {`Can't find your ${receiveSuggestionsLabel}? `}
                            <span className={classes[`rb-list-not-found-link`]} onClick={() => setOpenRequestDialog(true)}>
                                {`Request a new ${receiveSuggestionsLabel}`}
                            </span>
                            {` from Remitbee team, and we'll notify you when it's available.`}
                        </span>
                    </Typography>
                </DropDownItem>
            );
    }, []);

    useEffect(() => {
        if (searchFor) {
            handleFilter();
            return
        }
        if (onSearch) onSearch(query, receiveSuggestions);
    }, [query]);

    useEffect(() => {
        setPrimaryList(primaryListItems);
    }, [primaryListItems]);

    useEffect(() => {
        setOpen(dropListOpen);
    }, [dropListOpen]);

    const handleFilter = () => {
        if (query || (query && query !== '')) {
            const filtered = primaryListItems.filter(l => l.props?.data?.[searchFor]?.toUpperCase().includes(query?.toUpperCase()));
            filtered.length > 0 ? setPrimaryList(filtered) : setPrimaryList(receiveSuggestions)
        } else setPrimaryList(primaryListItems);
    }

    const handleClickOutside = (event: { target: any; }) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            setOpen(false);
            handleOutsideClick && handleOutsideClick(false);
            if (dropListOpen && setDropListOpen) setDropListOpen(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            setOpen(false);
            if (setDropListOpen) setDropListOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleListItemClick = (e: any) => {
        e.preventDefault();
        if (!multiSelect && setDropListOpen) setDropListOpen(false)
        if (primaryList && receiveSuggestions && primaryList === receiveSuggestions) setOpenRequestDialog(true)
        setSearchQuery('');
    }

    if (!open) return null

    return (
        <div
            ref={innerRef || componentRef}
            className={`
            ${isTopView ? classes[`rb-dropDownList-wrapper-top`] : classes[`rb-dropDownList-wrapper`]}
            ${dropdownListClasses}
        `}
            {...rest}
        >
            <div onScroll={onScroll} className={classes[`rb-dropDownList-container`]}>
                {(onSearch || searchFor) &&
                    <Box className={classes['rb-searchBox-mobile-container']}>
                        <div className={classes[`rb-dropDown-searchBox`]}>
                            <SearchIcon width={24} height={24} color="#9C9DA4" />
                            <input
                                type="text"
                                id='search'
                                autoFocus
                                value={query}
                                placeholder={searchPlaceHolder}
                                className={classes[`rb-search-input`]}
                                onChange={({ target: { value } }) => setSearchQuery(value)}
                            />
                        </div>
                    </Box>
                }
                <div
                    className={classes[`rb-lists-container`]}
                    id='list-container'
                    style={{ gap: !secondaryListItems && 0 }}
                >
                    <div className={classes[`rb-list-children`]}>
                        {primaryListLabel && <Box mt={4} className={classes[`rb-list-header`]}>
                            <Typography variant='h5' weight='semibold' color={theme.palette.primary.navy} className={classes['rb-list-header-text']}>{primaryListLabel}</Typography>
                        </Box>}

                        {((!primaryList || !primaryList.length) && !receiveSuggestions) ? <Box pr={4} pl={4} pt={4} pb={4}><Typography color={theme.palette.input.placeholder} variant='body1'>No results found</Typography></Box> :
                            <ul
                                className={`
                            ${classes[`rb-list-item-container`]}
                            ${primaryListClasses}
                        `}
                                onClick={handleListItemClick}
                            >
                                {primaryList}
                            </ul>}
                    </div>
                    {secondaryListItems && <div className={classes[`rb-list-children`]}>
                        {secondaryListLabel && <Box className={classes[`rb-list-header`]}>
                            <Typography variant='h5' weight='semibold' color={theme.palette.primary.navy} className={classes['rb-list-header-text']} >{secondaryListLabel}</Typography>
                        </Box>}
                        <div className={classes[`rb-list-item-container`]} onClick={handleListItemClick}>
                            {secondaryListItems}
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default DropDownList;